import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "utils/httpClient";
import { INTERVIEW } from "utils/constant";
import { useUserStore } from "store/user";
import { errorToaster, successToaster } from "utils/toaster";
import Logo from "assets/images/logo.png";
import Loader from "component/Loader";

function Login() {
  const navigate = useNavigate();
  const addUser = useUserStore((state) => state.addUser);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const result = await httpClient.post(INTERVIEW.START, values);
      if(result.data.msg !== undefined && result.data.msg === "Your interview is over, HR will update the details"){
        successToaster(result.data.msg);
      }
      else{
        const { question, interviewId, candidate , numberQuestionsDone } = result.data;
        addUser(question, interviewId, candidate, numberQuestionsDone, candidate.Test.numberOfSelectedQuestions );
        navigate("/main");
      }      
    } catch (err) {
      errorToaster(err?.response?.data?.msg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="vh-100 login-wrapper">
        <div className="login-page">
          <div className="form">
            <div style={{ background: "#306A88" }}>
              <img src={Logo} alt="Logo" />
            </div>
            {/* <h3>Login Form</h3> */}
            <form className="login-form mt-3" onSubmit={handleSubmit}>
              <input
                name="firstName"
                type="text"
                placeholder="First Name"
                onChange={handleChange}
                value={values.firstName}
                required
              />
              <input
                name="lastName"
                type="text"
                placeholder="Last Name"
                onChange={handleChange}
                value={values.lastName}
                required
              />
              <input
                name="email"
                type="email"
                placeholder="Email"
                onChange={handleChange}
                value={values.email}
                required
              />
              <button type="submit" disabled={loading}>
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
