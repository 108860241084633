import swal from "sweetalert";

const successToaster = (msg) => {
  swal({
    title: "Success",
    text: msg,
    icon: "success",
    buttons: false,
    timer: 2000
  });
};

const errorToaster = (msg) => {
  const error = msg || "Something went wrong";
  swal({
    title: "Error",
    text: error,
    icon: "error",
    buttons: false,
    timer: 2000
  });
};

export { successToaster, errorToaster };
