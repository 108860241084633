import React, { useState, useMemo, useEffect , useRef} from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "utils/httpClient";
import { useUserStore } from "store/user";
import { INTERVIEW } from "utils/constant";
import { calculateProgress } from "utils/common";
import { errorToaster } from "utils/toaster";
import Dicon from "assets/images/Icon.svg";
import aibot from "assets/images/Ai-renamebot.png";
import Loader from "component/Loader";
import InstructionModal from "component/InstructionModal";

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { FiberManualRecord, Stop } from '@mui/icons-material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import UndoIcon from '@mui/icons-material/Undo';

function ChatBox() {
  // const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [answer, setAnswer] = useState({
    userAnswer: "",
    aiAnswer: "",
    isSubmitted: false,
    message: "",
    numberQuestionsDone: 0
  });
  const [loading, setLoading] = useState("");
  const [fakeState, setFakeState] = useState("");
  
  const { user, addQuestion } = useUserStore((state) => state);

  const { candidate, questionNo, interviewId, question, totalNoOfQuestions } = user;  
  
  const { firstName, lastName } = candidate;
  const [ currentQuestion, setCurrentQuestion ] =  useState(0);
  
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const textareaRef = useRef(null);

  const onBlur = () => {
    stopListen()
  };

  useEffect(() => {
    const hasCodeExecuted = localStorage.getItem('modal');

    if (!hasCodeExecuted) {
      setShow(true);
      localStorage.setItem('modal', 'true');
    }

    window.addEventListener("blur", onBlur);

    return () => {
        window.removeEventListener("blur", onBlur);
        stopListen()
    };
  }, []);



  const handleCopy = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    try {
      resetTranscript()
      setLoading(true);
      const result = await httpClient.post(INTERVIEW.ANSWER, {
        answer: value,
        interviewId: interviewId,
        question: currentQuestion,
        numberOfQuestions : totalNoOfQuestions
      });
      setValue("");
      setLoading(false);
      const { validationOfPreviousQuestion } = result.data;
      setAnswer({
        userAnswer: value,
        aiAnswer: validationOfPreviousQuestion,
        isSubmitted: true,
        message: ((result.data && result.data.message !== undefined) ? result.data.message : ""),
        numberQuestionsDone: result.data.numberQuestionsDone
      });
      if(questionNo < totalNoOfQuestions){
        addQuestion(result.data.numberQuestionsDone)
      }
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        handleSubmit()
      } else {
        errorToaster(error?.response?.data?.msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const viewScore = async () => {
    navigate("/result");
  }

  const handleNext = async () => {
    try {
      setAnswer({
        userAnswer: "",
        aiAnswer: "",
        question: "",
        isSubmitted: false,
        numberQuestionsDone: answer.numberQuestionsDone
      });
      setQuestion();
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        handleNext()
      } else {
        errorToaster(error?.response?.data?.msg);
      }
    } finally {
      setLoading(false);
    }
  };

  const setQuestion = () => {
    setCurrentQuestion((totalNoOfQuestions >= questionNo) ? question[questionNo] : "")
  }

  const handleSkip = async () => {
    resetTranscript()
    try {
      setLoading(true);
      const result = await httpClient.post(INTERVIEW.ANSWER, {
        answer: "I don't know",
        interviewId: interviewId,
        question: currentQuestion,
        numberOfQuestions : totalNoOfQuestions
      });

      setLoading(false);      
      setValue("");      
      setAnswer({
        userAnswer: "",
        aiAnswer: "",
        isSubmitted: false,
        message: ((result.data && result.data.message !== undefined) ? result.data.message : ""),
        numberQuestionsDone: result.data.numberQuestionsDone
      });
      if(questionNo < totalNoOfQuestions){
        addQuestion(result.data.numberQuestionsDone)
      }  
      if(questionNo === (totalNoOfQuestions -1)){
        viewScore()
      }
      console.log("question No");
      console.log(questionNo);
      
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        handleSkip()
      } else {
        errorToaster(error?.response?.data?.msg);
      }
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    setCurrentQuestion((totalNoOfQuestions >= questionNo) ? question[questionNo]  : "")
  },[])

  useEffect(() => {
    setFakeState(!fakeState)    
    if(answer.aiAnswer === ""){
      setQuestion();
    }
  },[answer.numberQuestionsDone])

  const disable = useMemo(() => {
    return loading || answer.isSubmitted || !value.trim() ? true : false;
  }, [loading, answer.isSubmitted, value]);


  // record answer 
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const stopListen = ()=>{
    SpeechRecognition.stopListening();
  }

  const startListen = ()=>{
    SpeechRecognition.startListening({continuous: true});
  }

  const resetTranscriptFun = ()=>{
    resetTranscript()
    setValue("")
  }
  
  useEffect(()=>{
    setValue(transcript)
  },[transcript])

  
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [value]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const setValueOnChange = (e) => {
    if(e.target.value === ""){
      resetTranscript()
    }
    setValue(e.target.value)
  }


  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid mt-1 chatBotMainBox">
        <div className="row">
          <div className="col-sm-12 headerInnerChatBot ">
            <div className="titleInfoDiv ">
              <p className="fonthead-online">
                Online Test Instruction
                <img src={Dicon} alt="User Avatar" className="dangericon" onClick={handleShow}/>
              </p>
            </div>

            <div className="timerInfoDiv ">
            
              <div className="fonthead-online-2  ">
            
                <p>
                  {questionNo < totalNoOfQuestions ? (parseInt(questionNo) + 1) : questionNo } / {totalNoOfQuestions} Question
                </p>
              
                <div className="progress progress-striped">
                  <div
                    className="progress-bar progress-bar-danger"
                    role="progressbar"
                    aria-valuenow="80"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width: `${calculateProgress(
                        totalNoOfQuestions ,                        
                        parseInt(questionNo)
                      )}%`,
                    }}
                  ></div>
                </div>
              </div>

              {/* <div className="clockCountDownDiv gapflex-c wrap-w">
                <div className=" gapitem  font-color-g ms-1 fonts">
                  <img src={AlarmClock} alt="User Avatar" className="w2remw clock_img" />{" "}
                  <label className="timer">1h 42min</label>
                  <button
                    type="button"
                    className="btn wtd130 bg-blue-i  finishTestBtn"
                  >
                    Finish Test
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-sm-12 contentInnerDiv mt-2">
            <div className="col-sm-12 questionDiv order-2">
              <div className="test-container">
                <div className="card scrollcss">
                  <div className="card-header bg-blue-i font-color-w justify-content-start d-flex questionDivBox boxcardheight1 scrollcss" onCopy={handleCopy}>
                    <span className="imgDivBox">
                      <img src={aibot} alt="User Avatar" className=" " />
                    </span>
                    <span className="ms-2 d-flex align-items-center">
                      {currentQuestion}
                    </span>
                  </div>

                  {answer.isSubmitted && answer.userAnswer && (
                    <>
                      <div className="card-body bg-pin-g justify-content-start d-flex boxcardheight scrollcss">
                        <span className="imgHeadDivBox">
                        {firstName?.slice(0,1)}{lastName?.slice(0,1)}
                        </span>

                        <p className="card-text ms-2 font-text-card">
                          {answer.userAnswer}
                        </p>
                      </div>
                      <div className="card-body bg-pin justify-content-start d-flex boxcardheight scrollcss">
                        <span className="imgDivBox">
                          <img src={aibot} alt="User Avatar" className=" " />
                        </span>

                        <p className="card-text ms-2 font-text-card">
                          {answer.aiAnswer?.replace("As an AI language model,", "")}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 answerInnerDiv">
            {!answer.isSubmitted &&
              <div className="answerTextareaDiv">
                <textarea
                  placeholder="Enter Your Answer here"
                  className="form-control "
                  id="exampleFormControlTextarea1 "
                  onChange={(e) => setValueOnChange(e)}
                  value={value}
                  ref={textareaRef}
                  onPaste={(e)=>{
                    e.preventDefault()
                    return false;
                  }} onCopy={(e)=>{
                    e.preventDefault()
                    return false;
                  }}
                ></textarea>
              </div>
            }

            <div className="answerTextareaBtnDiv">
            {questionNo < totalNoOfQuestions && (questionNo !== (totalNoOfQuestions )) && !answer.isSubmitted &&   
            <button className='micStart' type="button" title={listening?`Close Mic`:`Open Mic` } onClick={!listening?startListen:stopListen} style={{ background: listening ? '#bcd0db' : '#e3bfbc', borderRadius: "5px", border: "none", margin: "0px 5px", padding: "10px" }}>
                {listening?(
                   <MicIcon style={{ color: '#306A88' }} title="Open Mic" />
                  ):(
                    <MicOffIcon style={{ color: 'red' }} title="Close Mic" />
                )}
              </button>
               }
             {questionNo < totalNoOfQuestions && (questionNo !== (totalNoOfQuestions )) && !answer.isSubmitted &&  
             <button type="reset" title="Reset" onClick={resetTranscriptFun} style={{ background: '#bcd0db', borderRadius: "5px", border: "none", margin: "0px 5px", padding: "10px", color: '#306A88' }}>
                <UndoIcon />
              </button> }
            {questionNo < totalNoOfQuestions && (questionNo !== (totalNoOfQuestions )) && !answer.isSubmitted && <button
                type="button"
                className="btn bg-blue-i-submit "
                onClick={handleSubmit}
                disabled={disable}
              >
                Submit
              </button>}
              <div className="button-d">
                {questionNo < totalNoOfQuestions && (questionNo !== (totalNoOfQuestions )) && !answer.isSubmitted &&
                  <button
                    type="button"
                    className="btn  button-g"
                    onClick={handleSkip}
                    disabled={loading ? true : false}
                  >
                    I don't know
                  </button>
                }

                {questionNo < totalNoOfQuestions && (questionNo !== (totalNoOfQuestions )) && answer.isSubmitted &&
                  <button
                  type="button"
                  className="btn  button-green"
                  onClick={handleNext}
                  >
                    Next
                  </button>
                }

                {(questionNo === (totalNoOfQuestions)) &&
                  <button
                    type="button"
                    className="btn  button-green"
                    onClick={viewScore}
                  >
                    View Score
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

       <InstructionModal show={show} setShow={setShow}/>
    </>
  );
}

export default ChatBox;
