import React from "react";
import Header from "component/header";
import ChatBox from "component/chatbox";

function Main() {
  return (
    <div className="main">
      <Header />
      <div className="d-flex flex-column mt-2">
        <ChatBox />
      </div>
    </div>
  );
}

export default Main;
