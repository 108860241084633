import React, { useEffect, useState } from "react";
import { httpClient } from "utils/httpClient";
import { useUserStore } from "store/user";
import { INTERVIEW } from "utils/constant";
import { errorToaster, successToaster } from "utils/toaster";
import Loader from "component/Loader";

function Result() {
  const user = useUserStore((state) => state.user);
  const removeInterViewId = useUserStore((state) => state.removeIntverviewId);
  const { candidate, interviewId } = user;
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const results = async () => {
      try {
        setLoading(true);
        const res = await httpClient.post(INTERVIEW.RESULT, {
          candidateId: candidate?.id,
        });
        setScore(res?.data?.result.result);
        successToaster("Your interview is over, HR will update the details");
      } catch (err) {
        errorToaster(err?.response?.data?.message);
        // errorToaster("Your session is expired. Please try to login.")
      } finally {
        setLoading(false);
      }
    };
    results();
    if (interviewId) removeInterViewId();

    return () =>{
      localStorage.clear()
    }
  }, [score]);
  return (
    <>
      {loading && <Loader />}
      <div className="vh-100 d-flex justify-content-center align-items-center score_page">
        <div className="card col-md-4 bg-white shadow-md p-5">
          <div className="mb-4 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="text-success bi bi-check-circle"
              width="75"
              height="75"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
          </div>
          <div className="text-center">
            <h1>Thank You !</h1>

            {!loading && <p>
              Your Score is <strong>{score}</strong>
            </p>
}
          </div>
        </div>
      </div>
    </>
  );
}

export default Result;
