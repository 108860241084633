import { createBrowserRouter } from "react-router-dom";
import Login from "pages/login";
import Main from "pages/main";
import Protected from "./Protected";
import Result from "pages/result";
import Open from './Open'

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Open Component={Login} />,
  },
  {
    path: "/main/:id",
    element: <Main />,
  },
  {
    path: "/main",
    element: <Protected Component={Main} />,
  },
  {
    path: "/result",
    element: <Result />,
  },
]);

export default routes;
