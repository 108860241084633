import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useUserStore = create(
  persist(
    (set) => ({
      user: {
        interviewId: "",
        question: "",
        candidate: "",
        questionNo: 0,
        totalNoOfQuestions: 0
      },
      addUser: (question, interviewId, candidate, numberQuestionsDone, totalNoOfQuestions) =>
        set({
          user: {
            interviewId: interviewId,
            question: ( question.length > 0 ) ? question : [],
            candidate: candidate,
            questionNo: numberQuestionsDone,
            totalNoOfQuestions : totalNoOfQuestions
          },
        }),
      addQuestion: (numberQuestionsDone) =>
        set((state) => ({
          user: {
            ...state.user,
            questionNo: numberQuestionsDone,
          },
        })),
      removeIntverviewId: () =>
        set((state) => ({
          user: {
            ...state.user,
            interviewId: "",
          },
        })),
    }),
    {
      name: "user-storage", // unique name
    }
  )
);
