import React from "react";
import { useUserStore } from "store/user";
import Logo from "assets/images/MPlogo-Light.png";
import Dropdown from 'react-bootstrap/Dropdown';

function Header() {
  const user = useUserStore((state) => state.user);
  const { firstName, lastName } = user.candidate;

  const logout = () => {
    localStorage.clear()
    window.location.href = "/"
  }


  return (
    <>
      <>
        <div className="container-fluid p-2 headerMainDiv bg-main-c text-white d-flex justify-content-between align-items-center wrap-w ">
          <div className="head-padd">
            <img src={Logo} alt="User Avatar" className="logo" />
          </div>
          <div>
            <h1>Interview CodeBot</h1>
          </div>
          <div className="infologobox head-padd">
            <div className="info-logo-image">
              {/* <span className="d-block">Welcome</span> */}
              <span >
              <i>Welcome</i> {firstName} {lastName}
              </span>
            </div>

            <Dropdown className="imgHeadDivBox">
              <Dropdown.Toggle as="p">
              {firstName?.slice(0,1)}{lastName?.slice(0,1)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
              <Dropdown.Item onClick={logout}> Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            
                     
          </div>
        </div>
      </>
    </>
  );
}

export default Header;
