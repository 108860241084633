import Modal from 'react-bootstrap/Modal';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import UndoIcon from '@mui/icons-material/Undo';


const InstructionModal = ({show, setShow}) =>{
    const handleClose = () => setShow(false);

    return(
        <>
        <Modal show={show} onHide={handleClose} size="lg" centered className='instruction_modal'>
        <Modal.Header closeButton>
          <Modal.Title>Instructions for Online Test</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{background: '#fff', padding: '25px'}}>
          <p className='mb-4 text-center' style={{fontSize: '19px'}}><strong>Welcome to the online test! <br/>Please review the following instructions before beginning the test:</strong></p>
          <ul className='hint_list'>
          <li className='hint_wrap'>To answer the question, please use the designated textbox provided below.</li>
          <li className='hint_wrap'>To respond to the question using your voice, please click on the  <strong className='btn4 hint'><MicOffIcon/></strong>  icon, <br/>and click once more to stop recording. </li>
          <li className='hint_wrap'>To rewrite the answer from start, simply click on this <strong className='btn3 hint'><UndoIcon/></strong>  icon. </li>
          <li className='hint_wrap'>If you encounter a question to which you don't know the answer, <br/>you can proceed by clicking on the <strong className='btn3 hint'>I don't know</strong> button. </li>
          <li className='hint_wrap'>To submit your response, please click on the <strong className='btn1 hint'>Submit</strong> button. </li>
          <li className='hint_wrap'>To move on to the next question, please click on the <strong className='btn2 hint'>Next</strong> button. </li>
          <li className='hint_wrap'>Once you have completed the test, your Final Score will be displayed on the screen.</li>

    </ul>
        </Modal.Body>
      </Modal>
        </>
    )
}

export default InstructionModal