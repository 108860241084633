import { RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import routes from "routes";
import IdleTimeOutHandler from './component/IdleTimeOutHandler'
import { useState } from 'react';

function App() {
  const[isActive,setIsActive]=useState(true)
  const[isLogout,setLogout]=useState(false)
  
  return  (
    <>
    <IdleTimeOutHandler onActive={()=>{setIsActive(true)}} onIdle={()=>{setIsActive(false)}} onLogout={()=>{setLogout(true)}} />
    <RouterProvider router={routes} />
    </>
  )
}

export default App;
