import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "store/user";

function Protected(props) {
  const { Component } = props;
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);

  useEffect(() => {
    if (!user.interviewId) navigate("/");
  }, [user.interviewId]);

  return (
    <div>
      <Component />
    </div>
  );
}

export default Protected;
